import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import { TextField, InputAdornment, CircularProgress } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import useDebounce from "../../hooks/useDebounce";
import clsx from "clsx";

const SearchBar = ({
  ["data-id"]: dataID,
  label,
  onChange,
  delay,
  isLoading,
  placeholder,
  small = false,
  defaultSearchTerm = null,
  className,
  icon,
  value,
  disabled,
  autoFocus = false
}) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm);
  const debouncedSearchTerm = useDebounce(searchTerm, delay);
  const refTerm = useRef(debouncedSearchTerm);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  useEffect(() => {
    setSearchTerm(defaultSearchTerm);
  }, [defaultSearchTerm]);
  
  useEffect(() => {
    if (
      debouncedSearchTerm !== refTerm.current &&
      (debouncedSearchTerm || debouncedSearchTerm === '')
    ) {
      refTerm.current = debouncedSearchTerm;
      onChange(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  return (
    <TextField
      data-id={dataID}
      className={clsx(
        classes.root,
        className,
        small ? classes.smallSearchBar : classes.searchBar
      )}
      label={label}
      disabled={disabled}
      variant="outlined"
      placeholder={placeholder ?? "Filter"}
      size="small"
      margin="dense"
      onChange={handleSearchChange}
      value={searchTerm ?? ""}
      inputProps={{
        "data-testid": dataID
      }}
      autoFocus={autoFocus}
      InputProps={
        icon ? (
          {
            startAdornment: (
              <InputAdornment>
                {isLoading === true ? (
                  <CircularProgress size="1.5em" />
                ) : (
                  <SearchIcon className={classes.searchIcon} />
                )}
              </InputAdornment>
            ),
          }
        ) : (
          <></>
        )
      }
    />
  );
};

SearchBar.displayName = "SearchBar";

SearchBar.defaultProps = {
  delay: 500,
  isLoading: false,
  onChange: () => {},
  icon: true,
};

SearchBar.propTypes = {
  ["data-id"]: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  delay: PropTypes.number,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.bool,
};

export default SearchBar;
