import { makeStyles, lighten } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

  sectionHeader: {
    fontWeight: "bold",
    padding: "0.25em 0",
    color: theme.palette.text.secondary
  },

  resultsContainer: {
    display: "flex",
    flexDirection: "column"
  },

  /** Result components, shared CSS */

  expandedDetailsContainer: {
    margin: "auto",
    padding: "1em",
    border: `solid 1px ${theme.palette.grey.main}`,
    borderRadius: "5px",
    width: "96%" /* avoids horizontal scrollbar */
  },

  footerDiv: {
    marginTop: "0.5em",
    display: "flex"
  },

  collapseResult:{
    color: theme.palette.text.secondary,
    fontSize:"smaller",
  },

  resultRow: {
    display: "flex",
    alignItems: "center",
    padding: "0.5em",
    margin: "0.25em 0"
  },

  clickableRow: {
    cursor: "pointer",
    "&:hover": {
      boxShadow: `0.5px 0.5px 0.5px 0.5px ${theme.palette.action.disabledBackground}`,
      backgroundColor: theme.palette.type === "light" 
        ? lighten(theme.palette.primary.light,.8) 
        : lighten(theme.palette.primary.light,.1),
      color: theme.palette.type === "light" 
        ? "inherit"
        : theme.palette.grey[900]
    },
    "&:focus": {
      boxShadow: `0.5px 0.5px 0.5px 0.5px ${theme.palette.action.disabledBackground}`,
      backgroundColor: theme.palette.type === "light" 
        ? lighten(theme.palette.primary.light,.8) 
        : lighten(theme.palette.primary.light,.1),
      color: theme.palette.type === "light" 
        ? "inherit"
        : theme.palette.grey[900]
    },
    transition: theme.transitions.create("box-shadow", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
  },

  displayValueContext: {
    color: theme.palette.text.disabled,
    fontSize: "0.85em",
    paddingBottom: "0.1em"
  },

  displayValue: {
    color: theme.palette.text.primary
  },

}));