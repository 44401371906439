import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    centered: {
        textAlign: "center",
    },

    smallTopMargin: {
        marginTop: "4px",
    },

    topSpace: {
        marginTop: "0.75em",
    },

    bottomSpace: {
        marginBottom: "0.75em",
    },

    recentActivityGrid: {
        margin: "8px 0 0 0"
    },

}));