import React, { useState, useEffect } from "react";
import BaseSearchModal from "./BaseSearchModal";
import TicketService from "../../../services/TicketService";
import apiClient from "../../../auth/apiClient";
import { Box, Typography, Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import moment from "moment-timezone";

const ticketService = new TicketService(apiClient);

const Value = ({ value, classes }) => {
  return (
    <Typography data-value={value} variant="h6" className={clsx(classes.centered)}>
      {value}
    </Typography>
  );
};

const Label = ({ title, classes }) => {
  return (
    <Typography color="textSecondary" className={clsx(classes.centered, classes.labelTitle)}>
      {title}
    </Typography>
  );
};

const TicketModal = ({ onClose, credentialReference }) => {
  const [ticketData, setTicketData] = useState(null);
  const [times, setTimes] = useState({ entry: null, exit: null });
  const classes = useStyles();

  const loadData = async () => {
    if (!credentialReference) return;
    try {
      const response = await ticketService.retrieveTicketById(credentialReference);
      setTicketData(response.data);
    } catch (err) {
      console.log("Modal Error", err);
    }
  };

  useEffect(() => {
    setTimes({ entry: formatDt(ticketData?.issuedDate), exit: formatDt(ticketData?.expiredDate) });
  }, [ticketData]);

  const formatDt = (dt) => {
    if (!dt) {
      return null;
    }
    return moment
      .utc(dt)
      .local()
      .format("llll");
  };

  return (
    <BaseSearchModal onClose={onClose} loadData={loadData} title="Ticket">
      {ticketData && (
        <Grid container spacing={1} className="ticket-modal-content">
          <Grid item xs={12}>
            <Box className={clsx(classes.goUpper, classes.centered, classes.bottomSpace)}>
              <Typography color="textSecondary" gutterBottom className={clsx(classes.title)}>
                TICKET NUMBER
              </Typography>
              <Typography data-id={ticketData.ticketID} variant="h5" color="primary" className={clsx("ticket-number")}>
                <span>
                  {ticketData.ticketID.slice(0, ticketData.ticketID.length - 12)}
                  <b>{ticketData.ticketID.slice(ticketData.ticketID.length - 12)}</b>
                </span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className={clsx(classes.flexGridCell)}>
            <Label title="Status" classes={classes}></Label>
          </Grid>
          <Grid item xs={12} md={6} className={clsx("status")}>
            <Value value={ticketData.status} classes={classes}></Value>
          </Grid>
          {times.entry && (
            <>
              <Grid item xs={12} md={6} className={clsx(classes.flexGridCell)}>
                <Label title="Entry Time" classes={classes}></Label>
              </Grid>
              <Grid item xs={12} md={6} className={clsx("entry-time")}>
                <Value value={times.entry} classes={classes}></Value>
              </Grid>
            </>
          )}
          {times.exit && (
            <>
              <Grid item xs={12} md={6} className={clsx(classes.flexGridCell)}>
                <Label title="Exit Time" classes={classes}></Label>
              </Grid>
              <Grid item xs={12} md={6} className={clsx("exit-time")}>
                <Value value={times.exit} classes={classes}></Value>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </BaseSearchModal>
  );
};

export default TicketModal;
