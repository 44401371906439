import React, { useCallback, useEffect, useState } from "react"
import { Typography } from "@material-ui/core";
import _ from "lodash";
import { useStyles } from "./Results.style"
import { FindFacilityOfEntity } from "../../../../../state/slices/entityScope";
import { shallowEqual, useSelector } from "react-redux";
import { TicketResult } from "./TicketResult.index";
import { CredentialResult } from "./CredentialResult.index";
import { AccessHolderResult } from "./AccessHolderResult.index";

export default function Results({searchResults, searchTerm}) {

  const classes = useStyles();

  const facilityGroupId = useSelector(
    (state) => state.entityScope?.facilityGroupId
  );
  const availableScopes = useSelector(
    (state) => state.entityScope?.available, shallowEqual
  ) ?? [];
  const selectedScopes = useSelector(
    (state) => state.entityScope?.selected, shallowEqual
  ) ?? [];

  const renderAccessHolders = () => {
    if (!searchResults || _.isEmpty(searchResults.accessHolders)) {
      return <></>;
    }

    const accessHolders = [];
    for (const accessHolder of searchResults.accessHolders) {
      accessHolders.push(
        <AccessHolderResult accessHolderSearchResult={accessHolder} />
      );
    }
    return (
      <div>
        <Typography variant="h6" className={classes.sectionHeader}>Access Holders</Typography>
        {accessHolders}
      </div>
    );
  }

  const renderCredentials = () => {
    if (!searchResults || _.isEmpty(searchResults.credentials)) {
      return <></>
    }

    const credentials = [];
    for (const credential of searchResults.credentials) {
      credentials.push(
        <CredentialResult credentialSearchResult={credential} />
      );
    }

    return (
      <div>
        <Typography variant="h6" className={classes.sectionHeader}>Credentials</Typography>
        {credentials}
      </div>
    );
  }

  const renderTickets = useCallback(() => {
    if (!searchResults || _.isEmpty(searchResults.tickets)) {
      return <></>;
    }
    
    const tickets = [];
    for (const ticket of searchResults.tickets) {
      if (facilityGroupId) {
        const parentFacility = FindFacilityOfEntity(availableScopes, ticket.entityID);
        ticket.displayValueContext = parentFacility?.name;
      }
      tickets.push(
        <TicketResult ticketSearchResult={ticket} searchTerm={searchTerm} />
      );
    }

    return (
      <div>
        <Typography variant="h6" className={classes.sectionHeader}>Tickets</Typography>
        {tickets}
      </div>
    );
  }, [facilityGroupId, selectedScopes, searchTerm]);

  return(
    <div className={classes.resultsContainer}>
      {renderAccessHolders()}
      {renderCredentials()}
      {renderTickets()}
    </div>
  )
}