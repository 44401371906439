import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography, Grid, LinearProgress, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/pro-duotone-svg-icons";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";

import { useStyles } from "./TicketResultDetails.style";
import { useStyles as useSharedStyles } from "./Results.style";

import TicketService from "../../../../../services/TicketService";
import apiClient from "../../../../../auth/apiClient";
const ticketService = new TicketService(apiClient);

export const TicketResultDetails = ({ onClose, credentialReference }) => {

    const classes = useStyles();
    const sharedClasses = useSharedStyles()

    const [isLoading, setIsLoading] = useState(true);
    const [ticketData, setTicketData] = useState(null);
    const [times, setTimes] = useState({ entry: null, exit: null });

    const fetchTicket = useCallback(async () => {
        if (!credentialReference) {
            return;
        }
        try {
            setIsLoading(true);
            const response = await ticketService.retrieveTicketById(credentialReference);
            setTicketData(response.data);
        } catch (err) {
            console.log("Modal Error", err);
        } finally {
            setIsLoading(false);
        }
    }, [credentialReference]);

    useEffect(() => {
        setTimes({ entry: formatDt(ticketData?.issuedDate), exit: formatDt(ticketData?.expiredDate) });
    }, [ticketData]);

    useEffect(() => {
        if (_.isNil(ticketData)) {
            fetchTicket();
        }
    }, [credentialReference]);

    const formatDt = (dt) => {
        if (!dt) {
            return null;
        }
        return moment.utc(dt).local().format("llll");
    };

    const ValueRow = ({ label, value, valueClassName }) => {
        if (_.isNil(value)) {
            return <></>
        }
        return [
            <Grid item xs={12} md={6} key={label}>
                <Typography color="textSecondary" className={classes.centered}>
                    {label}
                </Typography>
            </Grid>,
            <Grid item xs={12} md={6} key={value}>
                <Typography data-value={value} variant="h6" className={clsx(classes.centered, valueClassName)}>
                    {value}
                </Typography>
            </Grid>
        ];
    }

    return isLoading
        ? <LinearProgress />
        : <div>
            {ticketData
                ? <Grid container spacing={1} className="ticket-modal-content">
                    <Grid item xs={12}>
                        <Box className={clsx(classes.centered, classes.bottomSpace)}>
                            <Typography color="textSecondary" gutterBottom>
                                TICKET NUMBER
                            </Typography>
                            <Typography data-id={ticketData.ticketID} variant="h5" color="primary" className={"ticket-number"}>
                                <span>
                                    {ticketData.ticketID.slice(0, ticketData.ticketID.length - 12)}
                                    <b>{ticketData.ticketID.slice(ticketData.ticketID.length - 12)}</b>
                                </span>
                            </Typography>
                        </Box>
                    </Grid>
                    <ValueRow label="Status" value={ticketData.status} valueClassName={"status"} />
                    <ValueRow label="Entry Time" value={times.entry} />
                    <ValueRow label="Exit Time" value={times.exit} valueClassName={"exit-time"} />
                </Grid>
                : <p>Failed to load ticket</p>
            }
            <div className={clsx(classes.footerDiv, classes.topSpace)}>
                <Button
                    startIcon={<FontAwesomeIcon icon={faCaretUp} />}
                    className={clsx(sharedClasses.collapseResult, "collapse")}
                    onClick={onClose}>
                    Collapse
                </Button>
            </div>
        </div>
};