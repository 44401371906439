import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import ContractService from "../../../services/ContractService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import _ from "lodash";
import useHasPermissions from "../../../hooks/useHasPermissions";
import clsx from "clsx";
import SplitButton from "../../Buttons/SplitButton";
import { Typography } from "@material-ui/core";

const contractService = new ContractService(apiClient);

const availableModes = [
  { id: "normal", name: "Normal" },
  { id: "locked", name: "Locked" },
  { id: "override", name: "Override" },
  { id: "override ap", name: "Override AP" },
  { id: "inactive", name: "Inactive" }
];

const AccessHolderModeChangeButton = ({
  accessHolderDetails,
  onChangeStatusChange
}) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { hasPermissions } = useHasPermissions();
  const canUpdateMode = hasPermissions(["accessholders.edit"]);
  const [statusChanged, setStatusChanged] = useState(false);
  const [internalSelected, setInternalSelected] = useState(
    accessHolderDetails?.mode
  );

  const onSubmit = async () => {
    if (!statusChanged) {
      return;
    }
    if (!accessHolderDetails?.accessHolderID) {
      return;
    }

    try {
      await contractService.updateAccessHolderMode(
        accessHolderDetails.accessHolderID,
        internalSelected.replace(/\s/g, "")
      );
      onChangeStatusChange(internalSelected);
      enqueueSnackbar("Successfully updated access holder", {
        variant: "success"
      });
    } catch {
      enqueueSnackbar("Failed to retrieve access holder info", {
        variant: "error",
        tag: "FailedToRetrieveAccessholderInfo"
      });
    }
  };

  useEffect(() => {
    setStatusChanged(internalSelected !== accessHolderDetails?.mode);
  }, [internalSelected, accessHolderDetails]);

  const handleStatusChange = status => {
    setInternalSelected(status);
  };
  return (
    <>
      {canUpdateMode && accessHolderDetails?.mode ? (
        <div className="mode-split-btn">
          <SplitButton
            options={availableModes}
            onSubmit={() => {
              onSubmit();
            }}
            onChange={handleStatusChange}
            defaultSelected={accessHolderDetails?.mode}
            disabled={false}
          />
        </div>
      ) : (
        <Typography
          variant="h6"
          className={clsx(classes.centered)}
          data-value={accessHolderDetails?.mode}
        >
          {accessHolderDetails?.mode}
        </Typography>
      )}
    </>
  );
};

export default AccessHolderModeChangeButton;
