import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {},
  searchModalMenu: {
    marginTop:-13,
    backgroundColor:"unset",
  },
  floatSearch: {
    backgroundColor:"unset",
    '& input': {
      color:theme.palette.text.primary,
    },
  },

  /** Search icon/drawer */
  iconButton: {
    color: "inherit",
    [theme.breakpoints.down("md")]: {
      marginRight: "0"
    },
    "&& svg": {
      [theme.breakpoints.down("md")]: {
        fontSize: "1.75em"
      }
    }
  },
  drawer: {
		width: "100%",
		minWidth: "100%",
		[theme.breakpoints.up('sm')]: {
			width: "35vw",
			minWidth: 475,
      maxWidth: 750,
			flexShrink: 0,
		},
  },
  drawerContent: {
    flexGrow: 1,
    padding: "20px 20px 0 20px",
    height: "95vh"
  },
  drawerFooter: {
    display: "flex",
    padding: "10px",
    borderTop: `solid 2px ${theme.palette.divider}`
  },
  drawerCloseBtn: {
    width: "120px",
    marginLeft: "auto"
  },
}));