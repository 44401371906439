import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import useDebounce from "../../../hooks/useDebounce";
import { useStyles } from "./AutoCompleteSearchBar.style";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { USER_TYPES } from "../../../constants";

const AutoCompleteSearchBar = ({
  searchedItems,
  onItemChange,
  onItemSelected,
  loading,
  ["data-testid"]: dataTestID,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [focused, setFocused] = useState(false);
  const [arrayOfItems, setArrayOfItems] = useState(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const userType = useSelector((state) => state.user.UserType);
  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId);

  useEffect(() => {
    if (debouncedSearchTerm !== null) {
      const trimmedSearchTerm = String(debouncedSearchTerm).trim();
      onItemChange(trimmedSearchTerm);
    } else onItemChange(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (searchedItems && searchedItems.accessHolders) {
      var flattenedResults = searchedItems.accessHolders
        .map((x) => ({
          type: "Access Holders",
          id: x.id,
          displayValue: x.displayValue,
          entityID: x.entityID
        }))
        .concat(
          searchedItems.credentials.map((x) => ({
            type: "Credentials",
            id: x.id,
            displayValue: x.displayValue,
            entityID: x.entityID,
            displayValueContext: x.displayValueContext
          }))
        )
        .concat(
          searchedItems.tickets.map((x) => ({
            type: "Tickets",
            id: x.id,
            displayValue: x.displayValue,
            entityID: x.entityID,
            displayValueContext: x.displayValueContext
          }))
        );
      setArrayOfItems(flattenedResults);
    }
  }, [searchedItems]);

  const handleChange = (e) => {
    const validInput = e?.target?.value;
    setSearchTerm(validInput);
  };

  return (
    <div>
      {userType != USER_TYPES.ValidationAccount ? (
        <Autocomplete
          data-testid={dataTestID}
          className={clsx(["app-search", classes.autoComplete])}
          onInputChange={handleChange}
          options={arrayOfItems ?? []}
          delay={100}
          getOptionLabel={(option) => option["displayValue"]}
          open={focused && arrayOfItems?.length > 0}
          blurOnSelect
          onChange={(e, item) => onItemSelected(item)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          groupBy={(option) => option["type"]}
          autoHighlight
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              className={clsx([
                "app-search-input",
                classes.textField,
                classes.inputBox,
              ])}
              label={
                <span className={classes.textFieldPlaceholder}>Search</span>
              }
            />
          )}
          filterOptions={(option) => option}
          renderOption={(option) => (
            <div
              onClick={() => onItemSelected(option)}
              className={clsx([classes.listItemDiv])}
            >
              <span data-id={option["id"]} data-value={option["displayValue"]}>
                {facilityGroupID && option.type != "Access Holders" && (
                  <div className={clsx([classes.displayValueContext])}>{option["displayValueContext"]}</div>
                )}
                <div>{option["displayValue"]}</div>
              </span>
            </div>
          )}
          popupIcon={
            loading ? (
              <CircularProgress
                size={20}
                className={classes.progressIndicator}
              />
            ) : searchedItems?.length > 0 ? (
              <ArrowDropDownIcon />
            ) : (
              <></>
            )
          }
        />
      ) : (
        <></>
      )}
    </div>
  );
};

AutoCompleteSearchBar.defaultProps = {
  ["data-testid"]: "autocomplete-searchbar",
};

export default AutoCompleteSearchBar;
