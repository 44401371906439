import React, { useState, useEffect } from "react";
import BaseSearchModal from "./BaseSearchModal";
import SearchService from "../../../services/SearchService";
import _ from "lodash";
import { Grid, Typography } from "@material-ui/core";
import AccessHolderStatusChangeButton from "./AccessHolderStatusChangeButton";
import AccessHolderModeChangeButton from "./AccessHolderModeChangeButton";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useStyles } from "./styles";
import clsx from "clsx";
import { Paper, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import Moment from "react-moment";
import apiClient from "../../../auth/apiClient";
import moment from "moment";
import useCurrentFacilityTimezone from "../../../hooks/useCurrentFacilityTimezone";

const searchService = new SearchService(apiClient);

const AccessHolderModal = ({ onClose, accessholderid, facilityID }) => {
  const enqueueSnackbar = useEnqueueSnackbar();
  const [accessHolderDetails, setAccessHolderDetails] = useState();
  const [recentActivity, setRecentActivity] = useState(null);
  const [times, setTimes] = useState({ start: null, end: null });
  const classes = useStyles();

  const loadData = async () => {
    if (!accessholderid) {
      return;
    }
    try {
      const accessHolderResponse = await searchService.getAccessHolder(accessholderid, facilityID);

      setAccessHolderDetails(accessHolderResponse.data);
    } catch {
      enqueueSnackbar("Failed to retrieve access holder info", {
        variant: "error", tag: "FailedToRetrieveAccessholderInfo",
      });
    }
  };

  useEffect(() => {
    if (accessHolderDetails?.recentActivity && accessHolderDetails?.recentActivity?.length > 0) {
      setRecentActivity(accessHolderDetails.recentActivity[0]);
    }
    setTimes({
      start: formatDt(accessHolderDetails?.contractStartDate),
      end: formatDt(accessHolderDetails?.contractEndDate),
    });
  }, [accessHolderDetails]);

  const formatDt = (dt) => {
    if (!dt) {
      return null;
    }
    return moment
      .utc(dt)
      .local()
      .format("llll");
  };

  const nullFilter = (d) => {
    return d === "Invalid date" ? "" : d;
  };

  const TimesGrid = () => {
    return (
      <Grid container spacing={1}>
        {times.start && (
          <>
            <Grid item xs={12} md={times?.end ? 6 : 12}>
              <Typography color="textSecondary" className={clsx(classes.centered, classes.labelTitle)}>
                Start Date
              </Typography>
              <Typography className={clsx(classes.centered, "start-date")} data-value={times.start}>
                {times.start}
              </Typography>
            </Grid>
          </>
        )}
        {times?.end && (
          <>
            <Grid item xs={12} md={6}>
              <Typography color="textSecondary" className={clsx(classes.centered, classes.labelTitle)}>
                End Date
              </Typography>
              <Typography className={clsx(classes.centered, "end-date")} data-value={times.end}>
                {times.end}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const RecentActivity = () => {
    const { convertUtcDate } = useCurrentFacilityTimezone();
    return (
      <>
        <Typography
          color="textSecondary"
          className={clsx(classes.centered, classes.labelTitle, classes.smallTopMargin)}
        >
          Recent Activity
        </Typography>
        <Paper elevation={3} className={clsx(classes.smallTopMargin, classes.recentActivityGrid)}>
          <Table size="medium">
            <TableBody>
              <TableRow>
                <TableCell>Device Name</TableCell>
                <TableCell align="right" data-value={recentActivity.deviceName}>
                  {recentActivity.deviceName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell align="right" data-value={recentActivity.description}>
                  {recentActivity.description}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Activity Date</TableCell>
                <TableCell align="right">
                  <Moment
                    filter={nullFilter}
                    format="dddd, MMM D, YYYY hh:mm:ss A"
                    data-value={convertUtcDate(recentActivity?.activityDate)}
                    data-testid= "activity-date"
                  >
                    {convertUtcDate(recentActivity?.activityDate)}
                  </Moment>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reason</TableCell>
                <TableCell align="right" data-value={recentActivity.reason}>
                  {recentActivity.reason}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </>
    );
  };

  const accessHolderName = accessHolderDetails?.firstName?.concat(" ", accessHolderDetails?.lastName);

  return (
    <BaseSearchModal onClose={onClose} loadData={loadData} title="Access Holder">
      {accessholderid && (
        <>
          <Grid container spacing={1} className="access-holder-modal-content">
            <Grid item xs={12}>
              <Typography
                color="textSecondary"
                variant="h6"
                className={clsx(classes.centered, classes.bottomSpace, "access-holder-name")}
                data-value={accessHolderName}
              >
                {accessHolderName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                color="textSecondary"
                className={clsx(classes.centered, classes.labelTitle, classes.smallTopMargin)}
              >
                Status
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={clsx(classes.flexGridCell)}>
              <AccessHolderStatusChangeButton
                accessHolderDetails={accessHolderDetails}
                onChangeStatusChange={(details) => setAccessHolderDetails(details)}
              ></AccessHolderStatusChangeButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                color="textSecondary"
                className={clsx(classes.centered, classes.labelTitle, classes.smallTopMargin)}
              >
                Mode
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={clsx(classes.flexGridCell)}>
              <AccessHolderModeChangeButton
                accessHolderDetails={accessHolderDetails}
                onChangeStatusChange={(mode) => setAccessHolderDetails({ ...accessHolderDetails, mode: mode })}
              ></AccessHolderModeChangeButton>
            </Grid>
            {(times.start || times.end) && (
              <Grid item xs={12} className={clsx(classes.topSpace)}>
                <TimesGrid></TimesGrid>
              </Grid>
            )}
            {recentActivity && (
              <Grid item xs={12} className={clsx(classes.flexGridCell, classes.centered, classes.topSpace)}>
                <RecentActivity></RecentActivity>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </BaseSearchModal>
  );
};

export default AccessHolderModal;
