import React, { useState, useEffect,useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import TicketModal from "../Modals/TicketModal";
import AccessHolderModal from "../Modals/AccessHolderModal";
import AutoCompleteSearchBar from "./AutoCompleteSearchBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button, Drawer, IconButton } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import StyledMenu from "../../Modals/StyledMenu";
import apiClient from "../../../auth/apiClient";
import SearchService from "../../../services/SearchService";
import useCurrentFacility from "../../../hooks/useCurrentFacility";
import useCancellationToken from "../../../hooks/useCancellationToken";

import { useStyles } from "./index.styles";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import MultiSearch from "./MultiSearch/MultiSearch.index";
import { useFlags } from "launchdarkly-react-client-sdk";
const searchService = new SearchService(apiClient);

const MultiSearchBar = () => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [selectedAccessHolder, setSelectedAccessHolder] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [searchedItems, setSearchedItems] = useState(null);
  const theme = useTheme();
  const smallMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const [showPopoverSearch, setShowPopoverSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { facilityID } = useCurrentFacility();
  const selectedFacilityIds = useSelector((state) => state.entityScope.selected?.map((entity) => { return entity.id; }), shallowEqual) ?? [];
  const facilityGroupID = useSelector((state) => state.entityScope?.facilityGroupId, shallowEqual);
  const combinedEntityIDs = facilityGroupID ? [...selectedFacilityIds, facilityGroupID] : selectedFacilityIds;
  
  const { v2OmniSearch } = useFlags();

  const handleItemSelect = (item) => {
    if (!item) {
      setSelectedAccessHolder(null);
      setSelectedTicket(null);
    } else {
      if (item.type === "Tickets") {
        setSelectedTicket(item);
      } else {
        setSelectedAccessHolder(item);
      }
    }
  };

  useEffect(() => {
    if (value) {
      executeSearch({ combinedEntityIDs, facilityID, value });
    } else {
      cancelSearch();
    }
  }, [value]);

  useEffect(() => {
    if (!smallMatch) {
      setShowPopoverSearch(false);
    }
  }, [smallMatch]);

  const {
    execute: executeSearch,
    cancel: cancelSearch,
    inProgress,
  } = useCancellationToken({
    func: async function ({ combinedEntityIDs, facilityID, value, cancelToken }) {
      setSearchedItems(null);
      var result = await searchService.getSearchResults(
        combinedEntityIDs,
        facilityID,
        value,
        cancelToken
      );
      setSearchedItems(result.data);
    },
  });

  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const openSearchDrawer = () => {
    setSearchDrawerOpen(true);
  }
  const closeSearchDrawer = () => {
    setSearchDrawerOpen(false);
  }

  return v2OmniSearch ? [
    <Button
      key="omni-search-v2-button"
      className={clsx("open-search-drawer", classes.iconButton)}
      onClick={openSearchDrawer}>
      <FontAwesomeIcon icon={faSearch} />
    </Button>,
    searchDrawerOpen && (
      <Drawer
        key="omni-search-v2-drawer"
        PaperProps={{ classes: { root: clsx('search-drawer', classes.drawer) } }}
        anchor="right"
        open={searchDrawerOpen}
        onClose={closeSearchDrawer}
        ModalProps={{
          keepMounted: true,
        }}>
        <div className={classes.drawerContent}>
          <MultiSearch contextEntityId={facilityID} />
        </div>
        <div className={classes.drawerFooter}>
          <Button
            variant="contained"
            color="primary"
            onClick={closeSearchDrawer}
            className={clsx("close-search-drawer", classes.drawerCloseBtn)}>
            Close
          </Button>
        </div>
      </Drawer>
    )
  ] : (
    <>
      {smallMatch ? (
        <>
          <IconButton
            name="themeIcon"
            color="inherit"
            onClick={(event) => {
              setShowPopoverSearch(!showPopoverSearch);
              setAnchorEl(event.currentTarget);
            }}
            alt="Search"
            title="Search">
            <FontAwesomeIcon icon={faSearch} />
          </IconButton>

          {showPopoverSearch && (
            <StyledMenu
              id="search-menu"
              className={clsx(["search-modal-menu", classes.searchModalMenu])}
              anchorEl={anchorEl}
              keepMounted
              open={showPopoverSearch}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              onClose={() => setShowPopoverSearch(false)}>
              <div className={clsx(["float-search", classes.floatSearch])}>
                <AutoCompleteSearchBar
                  data-data-testid="multi-search"
                  searchedItems={searchedItems}
                  onItemChange={(v) => setValue(v)}
                  onItemSelected={handleItemSelect}
                  loading={inProgress}
                ></AutoCompleteSearchBar>
              </div>
            </StyledMenu>
          )}
        </>
      ) : (
        //not small match
        <AutoCompleteSearchBar
          data-testid="multi-search"
          searchedItems={searchedItems}
          onItemChange={(v) => setValue(v)}
          onItemSelected={handleItemSelect}
          loading={inProgress} />
      )}
      {selectedAccessHolder && (
        <AccessHolderModal
          onClose={() => setSelectedAccessHolder(null)}
          accessholderid={selectedAccessHolder?.id}
          facilityID={selectedAccessHolder?.entityID} />
      )}
      {selectedTicket && (
        <TicketModal
          credentialReference={selectedTicket.displayValue?.toUpperCase()}
          onClose={() => setSelectedTicket(null)} />
      )}
    </>
  )
};

export default React.memo(MultiSearchBar);
