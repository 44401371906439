import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},

  autoComplete: {
    minWidth: "20rem",
  },

  textField: {
    backgroundColor: theme.palette.action.selected,
    "& div.Mui-focused": {
      //border:"1px solid #c8c8c8",
    },
    "& label.Mui-focused": {},
  },
  inputBox: {
    marginLeft: 15,
    "& input": {
      color: "#fafafa",
    },
  },
  progressIndicator: {
    color: "#fafafa",
  },
  textFieldPlaceholder: {
    color: "silver",
    marginTop: -4,
    paddingTop: 0,
  },
  listItemDiv: {
    overflow: "auto"
  },
  displayValueContext: {
    color: "gray",
    fontSize: ".75em"
  },
}));
