import React, { useCallback, useState } from "react";
import { Grid, Typography } from "@material-ui/core";

import _ from "lodash";
import clsx from "clsx";

import { useStyles as useSharedStyles } from "./Results.style";
import { AccessHolderResultDetail } from "./AccessHolderResultDetail.index";

export const CredentialResult = ({
  credentialSearchResult
}) => {

  const sharedClasses = useSharedStyles();
  const [detailExpanded, setDetailExpanded] = useState(false);

  const openDetail = () => {
    setDetailExpanded(true);
  }

  const closeDetail = () => {
    setDetailExpanded(false);
  }

  const handleKeyDown = useCallback((event) => {
    if (!detailExpanded && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault();
      openDetail();
    }
  }, [detailExpanded, openDetail])

  return detailExpanded ?
    <div className={sharedClasses.expandedDetailsContainer} data-value={credentialSearchResult.displayValue}>
      <AccessHolderResultDetail accessHolderId={credentialSearchResult.id} onClose={closeDetail} />
    </div>
    :
    <div tabIndex={0}
      className={clsx(sharedClasses.resultRow, sharedClasses.clickableRow)}
      onKeyDown={handleKeyDown}
      onClick={openDetail}
      data-credential={credentialSearchResult.displayValue}>
      <Grid container>
        <Grid item xs={12} lg={10} className={sharedClasses.displayValueContext}>{credentialSearchResult.displayValueContext}</Grid>
        <Grid item xs={12} lg={10}>
          <Typography className={sharedClasses.displayValue}>{credentialSearchResult.displayValue}</Typography>
        </Grid>
      </Grid>
    </div>
};