import React from "react";
import { useStyles } from "./styles";
import ContractService from "../../../services/ContractService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import _ from "lodash";
import CredentialStatusChange from "../../Credentials/CredentialStatusChange";
import useHasPermissions from "../../../hooks/useHasPermissions";
import clsx from "clsx";
import { Typography } from "@material-ui/core";

const contractService = new ContractService(apiClient);

const AccessHolderStatusChangeButton = ({
  accessHolderDetails,
  onChangeStatusChange
}) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { hasPermissions } = useHasPermissions();
  const canEditAccessHolderIO = hasPermissions(["accessholders.edit"]);

  const handleAccessHolderStatusChange = async ({ status, reason }) => {
    let tmp = { ...accessHolderDetails };
    tmp.status = status;
    tmp.statusChangeReason = reason;

    try {
      await contractService.updateAccessHolderStatus(tmp.accessHolderID, {
        status: tmp.status,
        reason: tmp.statusChangeReason
      });
      onChangeStatusChange(tmp);
      enqueueSnackbar("Successfully updated access holder", {
        variant: "success"
      });
    } catch {
      enqueueSnackbar("Failed to update access holder", {
        variant: "error",
        tag: "FailedToUpdateAccessholder"
      });
    }
  };

  return (
    <>
      {canEditAccessHolderIO && accessHolderDetails?.status ? (
        <CredentialStatusChange
          className="status-split-btn"
          defaultSelected={accessHolderDetails?.status?.toLowerCase()}
          onSubmit={handleAccessHolderStatusChange}
        />
      ) : (
        <Typography
          variant="h6"
          className={clsx(classes.centered)}
          data-value={accessHolderDetails?.status}
        >
          {accessHolderDetails?.status}
        </Typography>
      )}
    </>
  );
};

export default AccessHolderStatusChangeButton;
