import React from "react";
import { Chip } from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import PropTypes from "prop-types";

const modes = {
  void: "error",
  backout: "warning",
  backout_with_ticket: "warning",
  active: "success",
  created: "success",
  payment: "info",
  exit: "info",
	enter: "info",
  primary:"primary",
	selected: "primary",
  unselected: "grey",
  red: "error",
  info: "info",
  warning: "warning",
};

const SelectableChip = ({
  id,
  className,
  variant,
  text,
  uppercase,
  selected,
  onClick,
  ...props
}) => {
  const classes = useStyles({ selected, variant });

  const handleChipClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <Chip
      {...props}
      className={clsx(className, classes.root, classes.square)}
      label={uppercase === true ? text.toUpperCase() : text}
      clickable={onClick !== undefined}
      onClick={handleChipClick}
      data-selected = {selected}
    />
  );
};

SelectableChip.defaultProps = {
  uppercase: false,
  onClick: undefined,
  selected: false,
};

SelectableChip.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  uppercase: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default SelectableChip;
