import { makeStyles, lighten,darken } from "@material-ui/core/styles";

//(╯°□°)╯︵ ┻━┻
export const useStyles = makeStyles((theme) => ({
  
  root: {

    margin:2,
    opacity:0.8,
    backgroundColor: (props) => {
        switch (props.variant) {
          case "primary":
            return lighten(theme.palette.amano.base.primary.light,0.4)
          case "secondary":
            return lighten(theme.palette.amano.base.secondary.main,0.3)
        
          default:
            return theme.palette.grey[300]
        }
    },

    "&[data-selected=true]": {
        backgroundColor: (props) => {
          switch (props.variant) {
            case "primary":
              return theme.palette.amano.base.primary.main;
            case "secondary":
              return theme.palette.amano.base.secondary.main      
            default:
              return theme.palette.grey[500]
          }      
        },
        opacity:1.0,
        color: (props) => {
          switch (props.variant) {
            case "primary":
              return theme.palette.common.white;
            case "secondary": 
              return theme.palette.common.white;
            default:
              return theme.palette.text.primary;
          }
        },
  },

    "&:hover, &.selected:hover": {
      backgroundColor: (props) => {
        switch (props.variant) {
          case "primary":
            return lighten(theme.palette.amano.main,0.3)
          case "secondary":
            return lighten(theme.palette.amano.warnings.warning,0.3)        
          default:
            return theme.palette.grey[300]
        }
      },
      opacity:1.0,
    },


  //   "&:hover": {
  //     backgroundColor: 
  //       (props) => 
  //       theme.palette[props.modes[props.mode]]?.light,
  //     color: "#fff",
  //   },
  //   "&:focus, &.selected": {
  //     backgroundColor: 
  //       (props) => 
  //       theme.palette[props.modes[props.mode]]?.main,
  //     color: "#fff",
  //   },    
  //   backgroundColor: (props) =>
  //       theme.palette[props.modes[props.mode]]?.dark,
  //   color: theme.palette.common.white,
  // }
  },

  square: {
    borderRadius: 3,
  },
}));
