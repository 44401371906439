import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

searchContainer: {
  display: "flex",
  justifyContent: "space-between",
  margin: "1.25em 0"
},

searchTerm: {
  marginRight: "1em",
  flexGrow: 1,
},

searchIcon: {
  display: "flex",
  margin: "0 auto"
},

select: {
  minWidth: "150px"
},

resultsHeader: {
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: "1em"
},

resultsHeaderText: {
  paddingLeft: "0.25em",
  display: "inline-flex",
  alignItems: "center",
  flexGrow: 1
},

container: {
  display: "flex",
  flexFlow: "column",
  height: "100%"
},

header: {
  flex: "0 1 auto"
},

results: {
  borderTop: `solid 2px ${theme.palette.divider}`,
  padding: "1.25em 0.5em",
  flex: "1 1 auto",
  overflowY: "auto",
  maxWidth: ""
},

footer: {
  flex: "0 1 2em"
}

}));