import React, { useCallback, useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import _ from "lodash";
import clsx from "clsx";

import { useStyles as useParentStyles } from "./Results.style";
import { TicketResultDetails } from "./TicketResultDetails.index";

export const TicketResult = ({
  ticketSearchResult,
  searchTerm
}) => {

  const sharedClasses = useParentStyles();
  const [detailExpanded, setDetailExpanded] = useState(false);
  const [replacementExpression, setReplacementExpression] = useState(null);
  const facilityGroupId = useSelector((state) => state.entityScope?.facilityGroupId);

  const openDetail = () => {
    setDetailExpanded(true);
  }

  const closeDetail = () => {
    setDetailExpanded(false);
  }

  const handleKeyDown = useCallback((event) => {
    if (!detailExpanded && (event.key === "Enter" || event.key === " ")) {
      event.preventDefault();
      openDetail();
    }
  }, [detailExpanded, openDetail])

  const HighlightSearch = ({ children = '' }) => {
    if (_.isNil(replacementExpression)) {
      return children;
    }
    const parts = String(children).split(replacementExpression);
    return parts.map((part, index) => (
      replacementExpression.test(part)
        ? <mark key={`mark_${index}`}>{part}</mark>
        : <span key={`span_${index}`}>{part}</span>
    ));
  };

  useEffect(() => {
    if (_.isEmpty(searchTerm)) {
      return;
    }
    const escapedSearchTerm = searchTerm.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    setReplacementExpression(new RegExp("(" + escapedSearchTerm + ")", "gi"));
  }, [searchTerm])

  return detailExpanded ?
    <div className={sharedClasses.expandedDetailsContainer} data-value={ticketSearchResult.displayValue}>
      <TicketResultDetails credentialReference={ticketSearchResult.displayValue} onClose={closeDetail} />
    </div>
    :
    <div tabIndex={0}
      className={clsx(sharedClasses.resultRow, sharedClasses.clickableRow)}
      onKeyDown={handleKeyDown}
      onClick={openDetail}
      data-ticket={ticketSearchResult.displayValue}>
      <Grid container>
        {facilityGroupId && (
          <Grid item xs={12} lg={10} className={sharedClasses.displayValueContext}>
            {ticketSearchResult.displayValueContext}
          </Grid>
        )}
        <Grid item xs={12} lg={10}>
          <Typography className={sharedClasses.displayValue}>
            <HighlightSearch>{ticketSearchResult.displayValue}</HighlightSearch>
          </Typography>
        </Grid>
      </Grid>
    </div>
};