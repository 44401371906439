import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, useTheme } from "@material-ui/core";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./styles";

const BaseSearchModal = ({ loadData, title, children, onClose }) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const classes = useStyles();

  const close = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  async function callLoadData() {
    setLoading(true);
    try {
      await loadData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (loadData) {
      callLoadData();
    }
  }, []);

  return (
    <Dialog open={open} onClose={close}>
      {title && <DialogTitle style={{ color: theme.palette.primary.main }}>{title}</DialogTitle>}
      {loading && (
        <div style={{ margin: "auto", padding: "1em" }}>
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <>
          <DialogContent className={clsx(classes.baseDialogContent)}>{children}</DialogContent>
        </>
      )}
      <DialogActions style={{ display: "flex" }}>
        <Button onClick={close} color="primary" className={clsx("close")}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BaseSearchModal;
