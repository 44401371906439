import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  centered: {
    textAlign: "center",
  },

  flexGridCell: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },

  goUpper: {
    textTransform: "uppercase",
  },

  title: {
    fontSize: 18,
  },

  smallTopMargin: {
    marginTop: "4px",
  },

  bottomSpace: {
    marginBottom: "2rem",
  },

  topSpace: {
    marginTop: "2rem",
  },

  recentActivityGrid: {
    margin:"8px 0 0 0"
  },

  [theme.breakpoints.up('sm')]: {
    baseDialogContent: {
      margin:"0 1rem 0 1rem"
    },
  },

  [theme.breakpoints.up('md')]: {
    baseDialogContent: {
      margin:"0 2rem 0 2rem"
    },
  },
}));
